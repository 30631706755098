export enum PostHogEvent {
  // eslint-disable-next-line no-unused-vars
  RESELLER_LOGIN = "reseller_login",
  // eslint-disable-next-line no-unused-vars
  ORGANIZATION_LOGIN = "organization_login",
  QUOTE_START = "quote_start",
  QUOTE_COMPLETE = "quote_complete",
  QUOTE_REQUEST_CONVERT = "quote_request_convert",
  QUOTE_CONVERTED = "quote_converted",
  QUOTE_UPDATED = "quote_updated",
}

export enum OpsFlags {
  RESELLERS_MANAGING_CLIENTS = "resellers-managing-clients",
}

export enum ReleaseFlags {
  SHOW_CLAIMS_AT_RESELLER = "show-claims-at-reseller",
  URL_INPUT_ON_PROSPECT_CREATION = "release_url-input-on-prospect-creation",
  CLAIMS_FNOL = "release_claims-fnol",
  EMPTY_TABLE = "release_empty-table",
}
